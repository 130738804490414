/* eslint-disable vue/attribute-hyphenation */
<template>
  <div class="page-container">
    <div align="center" class="container">
      <b-row align-v="center" class="row pt-3 pb-3">
        <div class="col-4">
          <b-form-group
            :label="$t('from')"
            label-for="from"
            label-cols="2"
            content-cols="1"
            label-align="right"
            label-size="sm"
            class="mb-0"
            align="left"
          >
            <datetime id="from" v-model="fromDate" smaller></datetime>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            :label="$t('untilAndIncluding')"
            label-for="to"
            label-cols="3"
            content-cols="0"
            label-align="right"
            label-size="sm"
            class="mb-0"
            align="left"
          >
            <datetime id="to" v-model="toDate" title="To"></datetime>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group class="mt-3" align-v="center">
            <b-form-radio-group v-model="allBuildings" size="sm">
              <b-form-radio value="false">{{
                $t('thisBuilding')
              }}</b-form-radio>
              <b-form-radio value="true">{{
                $t('allBuildings')
              }}</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </b-row>
      <apexchart
        id="chart"
        type="area"
        :options="chartData.options"
        :series="chartData.series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import OkkuApi from '@/services/OkkuApi'
import { Datetime } from 'vue-datetime'
import moment from 'moment-timezone'
import VueApexCharts from 'vue-apexcharts'
import store from '@/store'

export default {
  name: 'ReservationsAndCancellationsStatistics',
  components: {
    datetime: Datetime,
    apexchart: VueApexCharts
  },
  data() {
    return {
      hourlyStats: [],
      fromDate: moment()
        .startOf('month')
        .add(1, 'days')
        .toISOString(),
      toDate: moment()
        .endOf('month')
        .toISOString(),
      allBuildings: false,

      chartData: {
        options: {
          chart: {
            id: 'cancellations-and-reservations',
            type: 'line',
            animations: {
              initialAnimation: {
                enabled: false
              }
            },
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true
              }
            }
          },
          tooltip: {
            x: { format: 'dd-MMM-yyyy  HH:mm' }
          },
          xaxis: {
            type: 'datetime',
            labels: {
              format: 'dd-MMM'
            }
          }
        },
        series: [
          {
            name: 'series-1',
            data: [[30], [40], [45], [50], [49], [60], [70], [91]]
          }
        ]
      }
    }
  },
  watch: {
    fromDate() {
      this.getHourlyStats()
    },
    toDate() {
      this.getHourlyStats()
    },
    allBuildings() {
      this.getHourlyStats()
    }
  },
  mounted() {
    this.getHourlyStats()
  },
  methods: {
    home(event) {
      window.location.href = '/workplace'
    },
    async getHourlyStats() {
      const hourlyReservations = await OkkuApi.getHourlyStats(
        this.fromDate,
        this.toDate,
        this.allBuildings === 'true'
      )
      const hourlyCancellations = await OkkuApi.getHourlyCancellationStats(
        this.fromDate,
        this.toDate,
        this.allBuildings === 'true'
      )
      const cancellations = []

      hourlyCancellations.forEach(record => {
        cancellations.push([
          moment(record.datetime).valueOf(),
          record.cancellations
        ])
      })

      const reservations = []
      hourlyReservations.forEach(record => {
        reservations.push([
          moment(record.snapshot_date).valueOf(),
          record.reservations
        ])
      })

      this.chartData.series = [
        {
          name: this.$t('reservations'),
          data: reservations
        },
        {
          name: this.$t('cancellations'),
          data: cancellations
        }
      ]
    },
    csvExport(arrData, fileName) {
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += [
        Object.keys(arrData[0]).join(';'),
        ...arrData.map(item => Object.values(item).join(';'))
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute(
        'download',
        `${store.state.common.$organisation}-${fileName}.csv`
      )
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
#chart {
  margin: 35px auto;
  opacity: 0.9;
}
</style>
